import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GoogleAuthRedirectHandler = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const handleAuthStateChange = async (data: any) => {
            if (data.payload.event === "customOAuthState" && data.payload.data) {
                try {
                    const customState = JSON.parse(data.payload.data);
                    if (customState.platform === "Google" && customState.redirectURL !== "/") {
                        navigate(customState.redirectURL);
                        return;
                    }
                } catch (error) {
                    console.error("Error handling auth state change:", error);
                }
            }
        };

        const unsubscribe = Hub.listen("auth", handleAuthStateChange);

        return unsubscribe;
    }, [navigate]);
    return null;
};

export default GoogleAuthRedirectHandler;
